<template>
  <span>
    <v-btn
      v-if="!isAuthenticated"
      v-bind="$attrs"
      @click="dialog_auth = true"
      :style="btnStyle"
      :class="btnClass"
    >{{text}}
      <slot></slot>
    </v-btn>

    <v-btn
      v-else-if="!isVerifiedSmsEmail"
      v-bind="$attrs"
      @click="dialog_sms_email = true"
      :style="btnStyle"
      :class="btnClass"
    >{{text}}
      <slot></slot>
    </v-btn>
    <v-btn
      v-else-if="create && !isVerifiedBank"
      v-bind="$attrs"
      @click="dialog_bank = true"
      :style="btnStyle"
      :class="btnClass"
    >{{text}}
      <slot></slot>
    </v-btn>

    <v-btn
      v-else
      v-bind="$attrs"
      v-on="$listeners"
      :style="btnStyle"
      :class="btnClass"
    >{{text}}
      <slot></slot>
    </v-btn>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog_auth"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog_auth = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Informácia

          </v-toolbar-title>

        </v-toolbar>

        <v-card-text class="pa-5">
          Nie si ZAREGISTROVANÝ/Á alebo PRIHLÁSENÝ/Á a preto nemôžeš využívať túto možnosť.
          Ak si zaregistrovaný/á, tak sa prihlás a ak nie si ešte zaregistrovaný/á, plne sa zaregistruj a over svoju identitu. Potom môžeš využívať aj túto funkciu. Registrácia je možná <a href="/register">TU</a>.
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog_bank"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog_bank = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Informácia

          </v-toolbar-title>

        </v-toolbar>

        <v-card-text class="pa-5">
          <p>Ak chceš pridávať komentáre a reagovať v diskusiách, musíš ešte vykonať dva posledné kroky.</p>

          <p>1. Overenie tvojej identity prostredníctvom platby sumy minimálne <strong>{{getSettings.hladina_volic}}.- €</strong> (môžeš aj viac ale od jedného eura a viac, sa počíta tvoje overenie ako platné a systémy pridávania komentárov sa Ti sprístupnia).
            Tebou zvolenú sumu pošli z tvojho bankového účtu, ktorý si <span v-if="gender == 'MAN'">uviedol</span><span v-else>uviedla</span> pri registrácií.</p>

          <p>Použi <strong>VS {{user_id}}</strong>. Úhradu vykonaj na IBAN <strong>SK4983300000004111441114</strong>.</p>

          <p>Potom už len čakáš na pripísanie tvojej platby na náš účet v banke. Zvyčajne to býva na druhý pracovný deň.
            Náš systém sa dopytuje bankového systému každých 5 minút a po informácií z banky, že si <span v-if="gender == 'MAN'">poslal</span><span v-else>poslala</span> požadovanú sumu na overenie tvojej identity spáruje túto platbu
            s tvojou osobou a okamžite sa Ti sprístupní táto funkcia.</p>

          <p>2. Musíš si pridať fotografiu, preto nemôžeš pridávať príspevky do diskusie. <a href="/profile/1">Klikni tu a pridaj si najprv fotografiu v profile.</a></p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog_sms_email"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog_bank = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Informácia

          </v-toolbar-title>

        </v-toolbar>

        <v-card-text class="pa-5">
          <p>Gratulujeme, si úspešne <span v-if="gender == 'MAN'">zaregistrovaný</span><span v-else>zaregistrovaná</span>.</p>

          <p>Avšak pre možnosť hlasovania je potrebné overiť email a telefónne číslo.</p>

        </v-card-text>
        <!-- <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog_bank = false"
          >Close</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'FakeButton',
  inheritAttrs: true,

  components: {
  },

  props: {
    text: { type: String, required: false },
    btnClass: { type: String, default: "" },
    btnStyle: { type: String, default: "" },
    create: { type: Boolean, default: false }
  },

  data() {
    return {
      dialog_auth: false,
      dialog_bank: false,
      dialog_sms_email: false
    }
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender",
      "user_id"
    ]),

    ...mapGetters("settings", ["getSettings"]),

  },


  events: {},

  watch: {},

  mounted: function () {
  },

  methods: {
  },
};
</script>